<template>
  <header>
    <nav class="navigation">
      <router-link to="/buyers">Байера</router-link>
      <router-link to="/products">Продукты</router-link>
      <router-link to="/op">ОП</router-link>
      <router-link to="/influence">Influence</router-link>
    </nav>
   
  </header>
  <div
    v-if="influenceStore.influence.SpendPrevWeek"
    class="product rounded-lg border bg-card text-card-foreground shadow-sm mb-2"
  >
    <div class="py-3 px-4 border-b">
      <h2 class="text-lg font-semibold text-gray-800">Бюджет</h2>
    </div>
    <div class="p-4">
      <!-- Шапка для периодов -->
      <div class="grid grid-cols-2 gap-y-2 text-md">
        <div class="font-medium text-gray-500">Период</div>
        <div class="font-medium text-gray-500 text-right">Затраты</div>
        <!-- Данные для визуализации -->
        <div class="text-gray-700">Пред. неделя</div>
        <div class="text-right text-gray-900">
          {{ influenceStore.influence.SpendPrevWeek }}
        </div>
        <div class="text-gray-700">Пред. месяц</div>
        <div class="text-right text-gray-900">
          {{ influenceStore.influence.SpendPrevMonth }}
        </div>
        <div class="text-gray-700">Тек. неделя</div>
        <div class="text-right text-gray-900">
          {{ influenceStore.influence.SpendCurrentWeek }}
        </div>
        <div class="text-gray-700">Тек. месяц</div>
        <div class="text-right text-gray-900">
          {{ influenceStore.influence.SpendCurrentMonth }}
        </div>
        <div class="text-gray-700">Остаток бюджета</div>
        <div class="text-right text-gray-900">
          {{ influenceStore.influence.LeftBudget }}
        </div>
      </div>
    </div>
  </div>
  <div v-else><div class="loader"></div></div>
</template>

<script setup>
import { onMounted } from "vue";
import { useInfluenceStore } from "@/stores/influenceStore";
const influenceStore = useInfluenceStore();

onMounted(async () => {
  try {
    await influenceStore.loadProducts();
  } catch (error) {
    console.error("Ошибка при загрузке данных:", error);
  }
});
</script>

<style scoped>
.product {
  margin-top: 20px;
  max-width: 500px;
}


@media screen and (max-width: 1700px) {
  .product {
    font-size: 12px;
  }
}
</style>

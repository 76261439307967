import { createRouter, createWebHistory } from 'vue-router';
import ProductList from '../components/ProductList.vue';
import ProjectList from '@/components/ProjectList.vue';
import OpList from '@/components/OpList.vue';
import LoginPage from '@/components/LoginPage.vue'; // Импортируем компонент логина
import InfluencePage from '@/components/InfluencePage.vue';

// Определяем маршруты
const routes = [
  { path: '/', component: ProjectList },
  { path: '/login', component: LoginPage }, // Страница логина
  {
    path: '/products',
    component: ProductList,
    meta: { requiresAuth: true }, // Защищённый маршрут
  },
  {
    path: '/buyers',
    component: ProjectList,
    meta: { requiresAuth: true }, // Защищённый маршрут
  },
  {
    path: '/op',
    component: OpList,
    meta: { requiresAuth: true }, // Защищённый маршрут
  },
  {
    path: '/influence',
    component: InfluencePage,
    meta: { requiresAuth: true }, // Защищённый маршрут
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Добавляем глобальный guard для проверки авторизации
router.beforeEach((to, from, next) => {
  // Получаем токен из localStorage
  const token = localStorage.getItem('token');

  // Если пользователь пытается попасть на страницу логина и уже авторизован, перенаправляем на /buyers
  if (to.path === '/login' && token) {
    next('/buyers');
  } 
  // Если маршрут требует авторизации, проверяем наличие токена
  else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      // Если токена нет, перенаправляем на страницу логина
      next('/login');
    } else {
      next(); // Если токен есть, разрешаем доступ
    }
  } else {
    next(); // Для незащищённых маршрутов разрешаем доступ
  }
});

// При первом заходе на приложение, если токен существует, перенаправляем на /buyers
router.isReady().then(() => {
  const token = localStorage.getItem('token');
  if (!token) {
    router.push('/login');
  } 
});

export default router;

export function transformData(data) {
  const filteredData = data.filter(arr => arr.length > 0);


  const chunkSize = 7;
const result = [];

for (let i = 0; i < filteredData.length; i += chunkSize) {
  const chunk = filteredData.slice(i, i + chunkSize);
  result.push(chunk);
}


let projects = []
result.forEach(item=>{

  let project = {
    name: item[0][1],
    prevDaySpend: item[2][1],
    prevDayPrice:item[2][2],
    prevDayMoney:item[2][3],
    prevWeekSpend:item[3][1] ,
    prevWeekPrice:item[3][2] ,
    prevWeekMoney:item[3][3] ,
    prevMonthSpend: item[4][1],
    prevMonthPrice: item[4][2],
    prevMonthMoney: item[4][3],
    currentWeekSpend:item[5][1] ,
    currentWeekPrice: item[5][2],
    currentWeekMoney: item[5][3],
    currentMonthSpend:item[6][1] ,
    currentMonthPrice: item[6][2],
    currentMonthMoney: item[6][3]
  }
  projects.push(project)

})
return projects
}


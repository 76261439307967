<template>
  <div class="w-full">
    <!-- Поиск и фильтры -->

    <header>
      <nav class="navigation">
        <router-link to="/buyers">Байера</router-link>
        <router-link to="/products">Продукты</router-link>
        <router-link to="/op">ОП</router-link>
        <router-link to="/influence">Influence</router-link>
      </nav>

      <div class="menu flex justify-between items-center">
        <div class="menu-inner">
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Поиск по имени проекта..."
            class="border rounded px-4 py-2 w-full max-w-xs"
          />
          <div
            class="pagination flex justify-center items-center mt-4 space-x-4"
          >
            <button
              :disabled="currentPage <= 1"
              @click="changePage(currentPage - 1)"
              class="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition disabled:opacity-50"
            >
              Предыдущая
            </button>
            <span class="text-gray-700">
              {{ currentPage }} / {{ totalPages }}
            </span>
            <button
              :disabled="currentPage >= totalPages"
              @click="changePage(currentPage + 1)"
              class="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition disabled:opacity-50"
            >
              Следующая
            </button>
          </div>
        </div>

        <div class="autoplay-control">
          <label>
            <input
              type="checkbox"
              v-model="autoplay"
              @change="toggleAutoplay"
            />
            <span style="font-size: 15px">Автопрокрутка</span>
          </label>
        </div>
      </div>
    </header>

    <!-- Чекбокс для автопрокрутки -->

    <!-- Projects Display -->
    <div v-if="paginatedProjects.length" class="products w-full">
      <div
        class="project rounded-lg border bg-card text-card-foreground shadow-sm mb-2"
        v-for="(project, index) in paginatedProjects"
        :key="index"
      >
        <div class="py-3 px-4 border-b">
          <h2 class="text-lg font-semibold text-gray-800">
            {{ project?.name[0] || "Проект" }}
          </h2>
        </div>
        <div class="p-4">
          <!-- Шапка для периодов -->
          <div class="grid grid-cols-3 gap-y-2 text-md">
            <div class="font-medium text-gray-500">Период</div>
            <div class="font-medium text-gray-500 text-right">Спенд</div>
            <div class="font-medium text-gray-500 text-right">Цена</div>

            <!-- Визуализация данных с помощью grid -->
            <div class="text-gray-700">Пред. день</div>
            <div class="text-right text-gray-900">
              {{ project?.prevDaySpend || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.prevDayPrice || "0.00" }}
            </div>

            <div class="text-gray-700">Пред. неделя</div>
            <div class="text-right text-gray-900">
              {{ project?.prevWeekSpend || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.prevWeekPrice || "0.00" }}
            </div>

            <div class="text-gray-700">Пред. месяц</div>
            <div class="text-right text-gray-900">
              {{ project?.prevMonthSpend || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.prevMonthPrice || "0.00" }}
            </div>

            <div class="text-gray-700">Тек. неделя</div>
            <div class="text-right text-gray-900">
              {{ project?.currentWeekSpend || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.currentWeekPrice || "0.00" }}
            </div>

            <div class="text-gray-700">Тек. месяц</div>
            <div class="text-right text-gray-900">
              {{ project?.currentMonthSpend || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.currentMonthPrice || "0.00" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="loader"></div>
    </div>

    <!-- Pagination Controls -->
  </div>
</template>

<script setup>import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { useProductStore } from "../stores/productStore";

const searchQuery = ref("");
const currentPage = ref(1);
const itemsPerPage = ref(15);
const autoplay = ref(true); // Флаг для автопрокрутки
const autoScrollInterval = ref(null);
const productStore = useProductStore();

// Фильтрация по поисковому запросу
const filteredProjects = computed(() => {
  if (searchQuery.value) {
    return productStore.products.filter((project) =>
      project.name[0]?.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
  } else {
    return productStore.products;
  }
});

// Пагинация
const paginatedProjects = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return filteredProjects.value.slice(start, end);
});

// Общее количество страниц
const totalPages = computed(() => {
  return Math.ceil(filteredProjects.value.length / itemsPerPage.value);
});

// Смена страницы
const changePage = (page, isManual = true) => {
  if (page > 0 && page <= totalPages.value) {
    currentPage.value = page;

    // Останавливаем автопрокрутку при ручном переключении страниц
    if (isManual && autoplay.value) {
      autoplay.value = false;
      stopAutoScroll();
    }
  }
};

// Функция для автоматической прокрутки страниц
const startAutoScroll = () => {
  if (autoScrollInterval.value) {
    // Если интервал уже запущен, не запускаем новый
    return;
  }
  autoScrollInterval.value = setInterval(() => {
    if (currentPage.value < totalPages.value) {
      changePage(currentPage.value + 1, false); // Передаем false для isManual
    } else {
      changePage(1, false); // Передаем false для isManual
    }
  },10000); // Интервал 3 секунды
};

// Остановка автопрокрутки
const stopAutoScroll = () => {
  if (autoScrollInterval.value) {
    clearInterval(autoScrollInterval.value);
    autoScrollInterval.value = null;
  }
};

// Наблюдатель за изменениями в autoplay
watch(autoplay, (newValue) => {
  if (newValue) {
    startAutoScroll();
  } else {
    stopAutoScroll();
  }
  // Сохраняем состояние в localStorage
  localStorage.setItem('autoplay', newValue);
});

// Остановка автопрокрутки при размонтировании компонента
onUnmounted(() => {
  stopAutoScroll();
});

// Загрузка данных из стора
onMounted(async () => {
  try {
    await productStore.loadProducts();

    // Загружаем состояние автопрокрутки из localStorage
    const savedAutoplay = localStorage.getItem('autoplay');
    if (savedAutoplay !== null) {
      autoplay.value = savedAutoplay === 'true';
    }

    // Запускаем автопрокрутку, если autoplay включен
    if (autoplay.value) {
      startAutoScroll();
    }
  } catch (error) {
    console.error("Ошибка при загрузке данных:", error);
  }
});

</script>

<style scoped>
.products {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Пять колонок на десктопе */
  grid-auto-rows: minmax(150px, auto); /* Задаем минимальную высоту для строк */
  gap: 20px;
  width: 100%;
  padding-top: 20px;
}

/* Карточки проектов */

@media screen and (max-width: 1700px) {
  .project {
    font-size: 12px;
  }
}

/* Адаптация для экранов шириной до 1200px: 4 карточки на ряд */
@media screen and (max-width: 1200px) {
  .products {
    grid-template-columns: repeat(4, 1fr);
  }

  header{
    flex-direction: column;
  }

  .menu {

    flex-direction: column !important;
    margin-left: 0px !important;
    padding-left: 0px !important;
    align-items: flex-start !important;
  }
  .menu-inner{
    flex-direction: column;
  }
}

/* Адаптация для экранов шириной до 900px: 3 карточки на ряд */
@media screen and (max-width: 900px) {
  .products {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Адаптация для экранов шириной до 600px: 2 карточки на ряд */
@media screen and (max-width: 600px) {
  .products {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Адаптация для экранов шириной до 400px: 1 карточка на ряд */
@media screen and (max-width: 500px) {
  .products {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Лоадер для отображения, когда загружаются данные */


/* Другие стили остаются без изменений */
.menu {
  position: sticky;
  top: 0px;
  right: 20px;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  width: fit-content;
  margin-left: auto;
  z-index: 12;
  gap: 20px;
  font-size: 13px;

  padding: 10px;
  background: white;
}

.menu-inner {
  display: flex;
  gap: 20px;
}

.pagination {
  margin-top: 0px;
}

.pagination span {
  white-space: nowrap;
}

.text-right {
  text-align: right;
}

.autoplay-control label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pagination button {
  font-size: 13px;
  padding: 5px 20px;
}
</style>

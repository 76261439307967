<template>
  <div id="app">
 
    <router-view></router-view>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const isLoginPage = ref(false);

// Функция для проверки маршрута
const checkIfLoginPage = () => {
  isLoginPage.value = route.path === '/login';
};

// Проверяем маршрут при первой загрузке
checkIfLoginPage();

// Следим за изменениями маршрута
watch(route, () => {
  checkIfLoginPage();
});
</script>

<style>
body{
  padding-left: 20px;
  padding-right: 20px;
}
header{
  display: flex;
  min-height: 50px;
}
nav {
  display: flex;
  gap: 40px;
  margin-bottom: 0px;
  padding-left: 0px;
  z-index: 100;
  padding: 10px 0px;
  background: white;
  width:fit-content;
  z-index: 14;
}

nav a {
  text-decoration: none;
  color: #1f2937;
}

nav a.router-link-exact-active {
  font-weight: bold;
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

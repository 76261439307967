export function cleanAndSplitArrayIntoChunks(arr, chunkSize = 7) {
  // Удаляем пустые строки
  const cleanedArray = arr.filter((item) => {
    if (Array.isArray(item)) {
      return item.length > 0; // Оставляем только непустые массивы
    }
    return item !== "" && item !== null && item !== undefined; // Убираем пустые строки и значения null/undefined
  });

  // Разделяем массив на части по chunkSize элементов
  const result = [];
  for (let i = 0; i < cleanedArray.length; i += chunkSize) {
    result.push(cleanedArray.slice(i, i + chunkSize));
  }

  return result;
}

export function transformData(data) {

  return data.map((item) => {
    // Первый элемент массива - это имя покупателя
    const buyer = {
      name: item[0][0], // Имя покупателя всегда первый элемент первого массива
      projects: [],
    };

    let projects = item[0];
    projects.shift();
    let newArr = item;
    newArr.splice(0, 2)

    let periods = []

    newArr.forEach((elem) => {
      elem.shift();

      let chunkedArray = [];
      for (let i = 0; i < elem.length; i += 2) {
        chunkedArray.push(elem.slice(i, i + 2));
      }
      periods.push(chunkedArray)
    });
    projects = projects.filter((item) => item !== "");

    projects.forEach((item,index)=>{
      let project = {
        name:item,
        prevDaySpend:periods[0][index][0],
        prevDayPrice:periods[0][index][1],
        prevWeekSpend:periods[1][index][0],
        prevWeekPrice:periods[1][index][1],
        prevMonthSpend:periods[2][index][0],
        prevMonthPrice:periods[2][index][1],
        currentWeekSpend:periods[3][index][0],
        currentWeekPrice:periods[3][index][1],
        currentMonthSpend:periods[4][index][0],
        currentMonthPrice:periods[4][index][1],
      }
      buyer.projects.push(project)
    })
    console.log(periods,projects)
    // Обработка периодов (таблиц с показателями)

    return buyer;
  });
}

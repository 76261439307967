<template>
  <div class="w-full">
    <!-- Поиск и фильтры -->
    <header>
      <nav class="navigation">
        <router-link to="/buyers">Байера</router-link>
        <router-link to="/products">Продукты</router-link>
        <router-link to="/op">ОП</router-link>
        <router-link to="/influence">Influence</router-link>
      </nav>
      <div class="menu flex justify-between items-center mb-4">
        <input
          v-model="searchQuery"
          type="text"
          placeholder="Поиск по имени проекта..."
          class="border rounded px-4 py-2 w-full max-w-xs"
          />
        <div class="pagination flex justify-center items-center mt-4 space-x-4">
          <button
            :disabled="currentPage <= 1"
            @click="changePage(currentPage - 1)"
            class="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition disabled:opacity-50"
          >
            Предыдущая
          </button>
          <span class="text-gray-700">{{ currentPage }} / {{ totalPages }}</span>
          <button
            :disabled="currentPage >= totalPages"
            @click="changePage(currentPage + 1)"
            class="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition disabled:opacity-50"
          >
            Следующая
          </button>
        </div>
      </div>
    </header>

    <!-- Projects Display -->
    <div v-if="paginatedProjects.length" class="products w-full">
      <div
        class="project rounded-lg border bg-card text-card-foreground shadow-sm mb-2"
        v-for="(project, index) in paginatedProjects"
        :key="index"
      >
        <div class="py-3 px-4 border-b">
          <h2 class="text-lg font-semibold text-gray-800">
            {{ project?.name || "Проект" }}
          </h2>
        </div>
        <div class="p-4">
          <!-- Шапка для периодов -->
          <div class="grid grid-cols-4 gap-y-2 text-md">
            <div class="font-medium text-gray-500">Период</div>
            <div class="font-medium text-gray-500 text-right">Лиды</div>
            <div class="font-medium text-gray-500 text-right">Встречи</div>
            <div class="font-medium text-gray-500 text-right">Деньги</div>

            <!-- Визуализация данных с помощью grid -->
            <div class="text-gray-700">Пред. день</div>
            <div class="text-right text-gray-900">
              {{ project?.prevDaySpend || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.prevDayPrice || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.prevDayMoney || "0.00" }}
            </div>

            <div class="text-gray-700">Пред. неделя</div>
            <div class="text-right text-gray-900">
              {{ project?.prevWeekSpend || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.prevWeekPrice || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.prevWeekMoney || "0.00" }}
            </div>

            <div class="text-gray-700">Пред. месяц</div>
            <div class="text-right text-gray-900">
              {{ project?.prevMonthSpend || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.prevMonthPrice || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.prevMonthMoney || "0.00" }}
            </div>

            <div class="text-gray-700">Тек. неделя</div>
            <div class="text-right text-gray-900">
              {{ project?.currentWeekSpend || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.currentWeekPrice || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.currentWeekMoney || "0.00" }}
            </div>

            <div class="text-gray-700">Тек. месяц</div>
            <div class="text-right text-gray-900">
              {{ project?.currentMonthSpend || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.currentMonthPrice || "0.00" }}
            </div>
            <div class="text-right text-gray-900">
              {{ project?.currentMonthMoney || "0.00" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="loader"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useOpStore } from "../stores/opStore";

const searchQuery = ref("");
const currentPage = ref(1);
const itemsPerPage = ref(15);
const productStore = useOpStore();

// Фильтрация по поисковому запросу
const filteredProjects = computed(() => {
  if (searchQuery.value) {
    return productStore.products.filter(
      (project) =>
        typeof project.name[0] === "string" &&
        project.name[0].toLowerCase().includes(searchQuery.value.toLowerCase())
    );
  } else {
    return productStore.products;
  }
});

// Пагинация
const paginatedProjects = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return filteredProjects.value.slice(start, end);
});

// Общее количество страниц
const totalPages = computed(() => {
  return Math.ceil(filteredProjects.value.length / itemsPerPage.value);
});

// Смена страницы
const changePage = (page) => {
  if (page > 0 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

// Загрузка данных из стора
onMounted(async () => {
  try {
    await productStore.loadProducts();
  } catch (error) {
    console.error("Ошибка при загрузке данных:", error);
  }
});
</script>

<style scoped>
.products {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Пять колонок на десктопе */
  gap: 20px;
  width: 100%;
  padding-top: 20px;
}

/* Адаптация для экранов до 1200px */
@media screen and (max-width: 1500px) {
  .products {
    grid-template-columns: repeat(4, 1fr); /* Четыре колонки */
  }
}

/* Адаптация для экранов до 900px */
@media screen and (max-width: 1200px) {
  .products {
    grid-template-columns: repeat(3, 1fr); /* Три колонки */
  }
}

/* Адаптация для экранов до 600px */
@media screen and (max-width: 900px) {
  .products {
    grid-template-columns: repeat(2, 1fr); /* Две колонки */
  }

  header{
    flex-direction: column;
  }

  .menu{
    margin-left: 0px !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding-left: 0px !important;
  }
}

/* Адаптация для экранов до 400px */
@media screen and (max-width:650px) {
  .products {
    grid-template-columns: 1fr; /* Одна колонка */
  }
}

.pagination{
  margin: 0px
}

.pagination span{
 white-space: nowrap;
}
.menu input{
  font-size: 13px;
}

.pagination{
  font-size: 13px;
}
.pagination button {
  font-size: 13px;
  padding: 5px 20px;
}

.menu {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  background-color: white;
  padding: 10px;
  z-index: 12;
  margin-left: auto;
}

.project .grid{
  column-gap: 10px !important; 
  white-space: nowrap;
}

@media screen and (max-width: 1700px) {
  .project {
    font-size: 12px;
  }
}
</style>
